<template>
  <div class="d-flex" style="height: 100%; max-height: 100%">
    <div class="statisticContainer">
      <div :style="(isShowFilter || !$vuetify.breakpoint.xs)?'height: unset;':'height: 56px; overflow: hidden;'"
           class="d-flex flex-wrap justify-sm-center pl-3 pl-sm-0 mt-3"
           style="width: 100%; background-color: rgba(65, 149, 24, 0.2); position: relative;">
        <div v-if="$vuetify.breakpoint.xs" style="position: absolute; top: 4px; right: 8px;">
          <v-icon class="white rounded-xl" style="font-size: 18px;" @click="isShowFilter=!isShowFilter">
            {{ (isShowFilter) ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
        </div>
        <div v-if="$vuetify.breakpoint.xs && !isShowFilter" class="py-1" style="height: 56px; width: 100%;">
          <div v-for="label in filterLabel" :key="label" class="text-center" style="width: 100%;">
            {{ label }}
          </div>
        </div>
        <div v-if="permission.includes('view statistics by selected date')" class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pl-sm-3 pr-3 py-2">
            ตั้งแต่วันที่
          </div>
          <div class="myInputWidth pr-3 py-2">
            <my-date-picker v-model="datefrom" buttonColor="primary white--text" color="primary"
                            header-color="primary"/>
          </div>
        </div>
        <div v-if="permission.includes('view statistics by selected date')" class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pl-sm-3 pr-3 py-2">
            ถึงวันที่
          </div>
          <div class="myInputWidth pr-3 py-2">
            <my-date-picker v-model="dateto" :min="datefrom" buttonColor="primary white--text" color="primary"
                            header-color="primary"/>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            ประเภทจุดตรวจวัด
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-select v-model="type" :items="measurementType" background-color="white" dense hide-details
                      item-text="name" item-value="id" outlined placeholder="ทั้งหมด"
                      @change="selectedParameter=''"/>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            พารามิเตอร์
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-autocomplete v-model="selectedParameter" :items="forSelectParameter" background-color="white" dense
                            hide-details item-text="name" item-value="id" outlined
                            placeholder="--เลือก--"></v-autocomplete>
          </div>
        </div>
        <div v-if="canShowRegion" class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            ภูมิภาค
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-select v-model="selectedRegion" :items="forSelectedRegion" background-color="white" dense hide-details
                      item-text="name" item-value="id" outlined placeholder="ทั้งหมด"
                      @change="selectedProvince=''"/>
          </div>
        </div>
        <div v-if="canShowProvince" class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            จังหวัด
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-autocomplete v-model="selectedProvince" :disabled="isLoadingFactory" :items="forSelectProvince"
                            background-color="white" dense hide-details item-text="name" item-value="id" outlined
                            placeholder="ทั้งหมด" @change="selectedFactory=''"></v-autocomplete>
          </div>
        </div>
        <div v-if="canShowFactory" class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            ประเภทโรงงาน
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-autocomplete v-model="selectedFactoryType" :items="forSelectFactoryType"
                            background-color="white" dense hide-details item-text="name" item-value="id" outlined
                            placeholder="ทั้งหมด" @change="selectedFactory=''; selectedMeasurement=''"></v-autocomplete>
          </div>
        </div>
        <div v-if="canShowFactory" class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            โรงงาน
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-autocomplete v-model="selectedFactory" :disabled="isLoadingFactory" :items="forSelectFactory"
                            background-color="white" dense hide-details item-text="name" item-value="id" outlined
                            placeholder="ทั้งหมด" @change="selectedMeasurement=''"></v-autocomplete>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            จุดตรวจวัด
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-autocomplete v-model="selectedMeasurement" :disabled="isLoadingFactory" :items="forSelectMeasurement"
                            background-color="white" dense hide-details item-text="name" item-value="id" outlined
                            placeholder="ทั้งหมด"></v-autocomplete>
          </div>
        </div>
        <div class="pr-3 py-2">
          <v-btn color="primary white--text" style="height: 40px;" @click="get()">
            ค้นหา
            <v-progress-circular v-show="isLoading" class="ml-3" indeterminate size="16"></v-progress-circular>
          </v-btn>
        </div>
      </div>
      <div class="pt-5">
        <v-row class="px-3 mx-0">
          <v-col class="pa-1 pa-sm-3" cols="12" sm="">
            <div class="rounded-lg fill-height pa-2 white--text" style="background-color: #EF3636;">
              <div id="largest-text-div" style="min-width: 90px;">จำนวนโรงงาน/จุดตรวจวัด</div>
              <div class="justify-center d-flex">
                <span class="bigNumber mx-1 my-2">
                  {{ numberWithCommas(factoryCount) }}/{{ numberWithCommas(countMeasurement) }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col class="pa-1 pa-sm-3" cols="12" sm="">
            <div class="rounded-lg fill-height pa-2 white--text" style="background-color: #1F73AA;">
              <div :style="`min-height: ${divHeight}px;`" style="min-width: 90px;">ค่าเฉลี่ย</div>
              <div class="justify-center d-flex">
                <span class="bigNumber mx-1 my-2"> {{ numberWithCommas(averageValue) }} </span>
              </div>
            </div>
          </v-col>
          <v-col class="pa-1 pa-sm-3" cols="12" sm="">
            <div class="rounded-lg fill-height pa-2 white--text" style="background-color: #EE9A1F;">
              <div :style="`min-height: ${divHeight}px;`" style="min-width: 90px;">ค่าสูงสุด</div>
              <div class="justify-center d-flex">
                <span class="bigNumber mx-1 my-2"> {{ numberWithCommas(maxValue) }} </span>
              </div>
            </div>
          </v-col>
          <v-col class="pa-1 pa-sm-3" cols="12" sm="">
            <div class="rounded-lg fill-height pa-2 white--text" style="background-color: #F05454;">
              <div :style="`min-height: ${divHeight}px;`" style="min-width: 90px;">ค่าต่ำสุด</div>
              <div class="justify-center d-flex">
                <span class="bigNumber mx-1 my-2"> {{ numberWithCommas(minValue) }} </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="pa-3 pt-5 pt-sm-3">
        <daily-line-chart v-if="graphValue && refreshChartSignal && graphValue.length > 0" :dataValue="graphValue"
                          :parameter="lineChartParameter"/>
        <div v-else class="d-flex justify-center align-center text-center" style="height: 250px; width: 100%;">
          ไม่พบข้อมูล
        </div>
      </div>
    </div>
    <div class="mapContainer">
      <div style="position: relative; width: 100%; height: 100%;">
        <thai-map-svg ref="map" v-on:mouseLeave="doLeave" v-on:provinceClick="doClick" v-on:provinceHover="doHover"/>
        <div v-show="isShowDescription" :style="descriptionStyle" class="grey white--text py-1 px-2"
             style="position: absolute;">
          {{ description.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '../assets/js/utils'
import DailyLineChart from '../components/charts/DailyLineChart'
import ThaiMapSvg from './thaiMapSvg'
import MyDatePicker from './myDatePicker'
import { mapState } from 'vuex'

export default {
  name: 'DailyStatistic',
  components: {
    MyDatePicker,
    ThaiMapSvg,
    DailyLineChart
  },
  async mounted () {
    function sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }

    this.reCalculateHeight()
    window.addEventListener('resize', this.reCalculateHeight)

    this.isLoading = true
    this.type = 1 // invoke watcher
    await this.$store.dispatch('ref/getParameterList')
    await this.$store.dispatch('ref/getMeasurementType')
    await this.$store.dispatch('ref/getFactoryType')
    this.filteredMeasurement = await this.$store.dispatch('ref/measurement', {})
    if (this.canShowProvince) {
      await this.$store.dispatch('getProvince')
    }
    if (this.canShowRegion) {
      await this.$store.dispatch('getRegion')
    }
    this.isLoadingFactory = true
    this.factory = await this.$store.dispatch('ref/getFactoryByProvinceId', { provinceId: this.province_id })
    this.isLoadingFactory = false

    this.markProvince()

    await sleep(100)
    if (this.factory_id) {
      this.selectedFactory = this.factory_id
    }
    await this.get() // you have to make sure that [ref/parameter] is present when calling this line
    this.isLoading = false
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reCalculateHeight)
  },
  data () {
    return {
      isShowFilter: true,
      divHeight: 48,
      type: '',
      selectedParameter: 4,
      forSelectParameter: [],
      datefrom: new Date().toISOString().substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      selectedRegion: '',
      selectedProvince: '',
      selectedFactoryType: '',
      selectedFactory: '',
      selectedMeasurement: '',
      factoryCount: '',
      countMeasurement: '',
      averageValue: '',
      maxValue: '',
      minValue: '',
      graphValue: [],
      payload: {},
      isShowDescription: false,
      refreshChartSignal: false,
      factory: [],
      description: { // province name description dialog, use in doHover(e)
        x: 0,
        y: 0,
        title: '',
        text: ''
      },
      lineChartParameter: {},
      isLoading: false,
      isLoadingFactory: false,
      filteredMeasurement: []
    }
  },
  methods: {
    reCalculateHeight () {
      const dom = document.getElementById('largest-text-div')
      if (dom) {
        this.divHeight = dom.offsetHeight
      }
    },
    numberWithCommas (x) {
      return utils.numberWithCommas(x || 0)
    },
    doHover (e) {
      this.description.x = e.offsetX
      this.description.y = e.offsetY
      this.description.title = e.name_th
      this.isShowDescription = true
    },
    doLeave (e) {
      this.isShowDescription = false
    },
    doClick (e) {
      const provinceId = parseInt(String(e).substr(3, 4))
      const provinceObject = this.province.find(item => item.id === provinceId)
      this.selectedRegion = provinceObject.id_parent
      this.selectedProvince = provinceObject.id
    },
    markProvince () {
      const provinceId = this.province_id
      this.$refs.map.markProvince(provinceId)
    },
    async get () {
      this.isLoading = true
      const payload = {
        uuid: this.uuid,
        type: this.type,
        parameter: this.selectedParameter || '',
        measurement: this.selectedMeasurement || '',
        datefrom: this.datefrom,
        dateto: this.dateto,
        region: this.selectedRegion || '',
        geocode: this.selectedProvince || '',
        type_factory: this.selectedFactoryType || '',
        factory: this.selectedFactory || ''
      }
      this.payload = { ...payload }
      this.refreshChartSignal = false
      this.lineChartParameter = this.parameter[String(this.type)].find(item => item.id === this.selectedParameter)
      const statData = await this.$store.dispatch('statistic/getDailyStat', payload)
      this.factoryCount = statData.count
      this.countMeasurement = statData.countMeasurement
      this.averageValue = statData.average
      this.maxValue = statData.max
      this.minValue = statData.min
      this.graphValue = statData.values
      this.refreshChartSignal = true
      this.isLoading = false
    },
    calculateForSelectParameter () {
      this.forSelectParameter = this.parameter[String(this.type)] || []
    }
  },
  computed: {
    ...mapState('user', ['permission', 'uuid', 'province_id', 'roles', 'factory_id']),
    ...mapState('ref', ['parameter', 'measurementType', 'parameterList', 'factoryType']),
    ...mapState(['province', 'region']),
    filterLabel () {
      const ret = []
      if (this.permission.includes('view statistics by selected date')) {
        ret.push(`วันที่ - ${utils.dateToThai(this.datefrom)} ถึง ${utils.dateToThai(this.dateto)}`)
      }
      const parameter = this.forSelectParameter.find(item => String(item.id) === String(this.selectedParameter)) || {}
      ret.push(`พารามิเตอร์ - ${parameter.name}`)
      return ret
    },
    canShowRegion () {
      let canShow = true
      canShow = canShow && !this.roles.includes('factory')
      canShow = canShow && (this.province_id === 0)
      return canShow
    },
    canShowProvince () {
      let canShow = true
      canShow = canShow && !this.roles.includes('factory')
      canShow = canShow && (this.province_id === 0)
      return canShow
    },
    canShowFactory () {
      let canShow = true
      canShow = canShow && !this.roles.includes('factory')
      return canShow
    },
    descriptionStyle () {
      return 'top:' + (this.description.y) + 'px; left:' + (this.description.x) + 'px;'
    },
    forSelectedRegion () {
      if (String(this.province_id) === '0' || !this.uuid) {
        return [{ id: null, name: 'ทั้งหมด' }, ...this.region]
      } else {
        const myProvince = this.province.find(item => String(item.id) === String(this.province_id))
        if (myProvince) {
          const parentId = myProvince.id_parent
          const region = this.region.filter(item => item.id === parentId)
          return [{ id: null, name: 'ทั้งหมด' }, ...region]
        } else {
          return [{ id: null, name: 'ทั้งหมด' }]
        }
      }
    },
    forSelectProvince () {
      let tmp = []
      if (String(this.province_id) === '0' || !this.uuid) {
        if (this.selectedRegion) {
          tmp = this.province.filter(item => String(item.id_parent) === String(this.selectedRegion))
        } else {
          tmp = this.province
        }
      } else {
        tmp = this.province.filter(item => String(item.id) === String(this.province_id))
      }
      return [{ id: null, name: 'ทั้งหมด' }, ...tmp]
    },
    forSelectMeasurement () {
      const tmp = this.filteredMeasurement.filter(item => String(item.type_id) === String(this.type))
      return [{ id: 0, name: 'ทั้งหมด' }, ...tmp]
    },
    forSelectFactory () {
      if (this.factory.length <= 0) {
        return []
      } else {
        return [{ id: 0, name: 'ทั้งหมด' }, ...this.factory]
      }
    },
    forSelectFactoryType () {
      return [{ id: 0, name: 'ทั้งหมด' }, ...this.factoryType]
    },
    factoryWatcherIndicator () {
      return this.selectedRegion + '_' + this.selectedProvince + '_' + this.selectedFactoryType
    },
    measurementWatcherIndicator () {
      return this.factoryWatcherIndicator + '_' + this.selectedFactory
    }
  },
  watch: {
    parameter: {
      handler () {
        this.calculateForSelectParameter()
      },
      deep: true
    },
    async type (to, from) {
      await this.$store.dispatch('ref/getParameterListByMeasurementType', { id_parent: to })
      this.calculateForSelectParameter()
    },
    async factoryWatcherIndicator (to, from) {
      let factoryOnFactoryType = []
      this.factory = []
      if (this.selectedRegion && !this.selectedProvince) {
        this.factory = await this.$store.dispatch('ref/getFactoryByRegionId', { regionId: this.selectedRegion })
      } else if (this.selectedProvince || this.province_id !== 0) {
        this.factory = await this.$store.dispatch('ref/getFactoryByProvinceId', { provinceId: this.selectedProvince || this.province_id })
      }
      if (this.selectedFactoryType) {
        factoryOnFactoryType = await this.$store.dispatch('ref/getFactoryByFactoryType', { id_parent: this.selectedFactoryType })
      }
      if (this.factory.length <= 0) {
        this.factory = factoryOnFactoryType
      } else if (this.factory.length > 0 && factoryOnFactoryType.length > 0) {
        const factoryOnFactoryTypeId = factoryOnFactoryType.map(item => (item.id))
        this.factory = this.factory.reduce(
          (acc, cur) => {
            let newArray = acc
            if (factoryOnFactoryTypeId.indexOf(cur.id) >= 0) {
              newArray = newArray.concat(cur)
            }
            return newArray
          }, []
        )
      } else {
        this.factory = [...this.factory]
      }
    },
    async measurementWatcherIndicator (to, from) {
      if (this.selectedFactory) {
        this.filteredMeasurement = await this.$store.dispatch('ref/measurement', { id_parent: this.selectedFactory })
      } else {
        const payload = {}
        if (this.selectedRegion) {
          payload.region = this.selectedRegion
        }
        if (this.selectedProvince) {
          delete payload.region
          payload.province = this.selectedProvince
        }
        if (this.selectedFactoryType) {
          payload.factoryType = this.selectedFactoryType
        }
        this.filteredMeasurement = await this.$store.dispatch('ref/measurementByQuery', payload)
      }
    }
  }
}
</script>

<style scoped>

@media (max-width: 600px) {
  .statisticContainer {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }

  .mapContainer {
    width: 0;
    max-height: 100%;
  }

  .myLabelWidth {
    width: 105px;
  }

  .myInputWidth {
    max-width: calc(100vw - 12px - 105px - 86px);
  }
}

@media (min-width: 600px) {
  .statisticContainer {
    width: calc(100% - min(600px, 40%));
    max-height: 100%;
    overflow-y: auto;
  }

  .mapContainer {
    width: min(600px, 40%);
    max-height: 100%;
    padding: 12px;
  }

  .myInputWidth {
    max-width: 170px;
  }
}

.bigNumber {
  font-size: 36px;
  line-height: 1;
}

</style>
