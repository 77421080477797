<template>
  <div id="DailyLineChart"></div>
</template>

<script>
import Highcharts from 'highcharts'
import Exporting from 'highcharts/modules/exporting'

Exporting(Highcharts)

export default {
  name: 'DailyLineChart',
  props: ['parameter', 'dataValue'],
  mounted () {
    Highcharts.setOptions({
      global: {
        useUTC: false
      },
      lang: {
        weekdays: ['วันอาทิตย์', 'วันจันทร์', 'วันอังคาร', 'วันพุธ', 'วันพฤหัสบดี', 'วันศุกร์', 'วันเสาร์'],
        shortMonths: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
      }
    })
    try {
      this.generateChart()
    } catch (e) {
      console.warn(e)
    }
  },
  methods: {
    generateChart () {
      Highcharts.chart('DailyLineChart', {
        chart: {
          // type: 'spline',
          zoomType: 'x'
        },
        title: {
          text: (this.parameter) ? this.parameter.name : ''
        },
        xAxis: {
          type: 'datetime',
          // dateTimeLabelFormats: {
          //   year: '%Y' + 543,
          //   month: '%e %b',
          //   day: '%e. %b',
          //   hour: '%H:%M น.'
          // },
          // labels: {
          //   formatter: (label) => {
          //     console.warn(label.dateTimeLabelFormat)
          //     return new Date(label.value).toLocaleString('th-TH', { day: 'numeric', month: 'short', year: '2-digit', hour: 'numeric', minute: 'numeric' })
          //   }
          // },
          title: {
            text: 'วัน เวลา'
          }
        },
        series: [
          {
            name: this.parameter.name,
            data: this.dataValue.map(item => [new Date(`${item.date.replace(/-/g, '/')} ${item.time}`).getTime(), item.value])
          }
        ]
      })
      window.dispatchEvent(new Event('resize')) // if not resize highcharts will create a chart which larger than div
    }
  }
}
</script>

<style scoped>
::v-deep text {
  font-family: "Roboto", 'Prompt', sans-serif !important;
}

::v-deep .highcharts-exporting-group {
  display: none;
}
</style>
