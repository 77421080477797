<template>
  <div style="height: 100%; max-height: 100%">
    <div class="d-flex flex-wrap justify-sm-center mt-3"
         style="width: 100%; background-color: rgba(65, 149, 24, 0.2);">
      <div v-if="canShowFactory" class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pl-sm-0 pr-3 py-2">
          ประเภทโรงงาน
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-autocomplete v-model="data.selectedFactoryType" :items="forSelectFactoryType"
                          background-color="white" dense hide-details item-text="name" item-value="id" outlined
                          placeholder="ทั้งหมด"
                          @change="data.id=''; data.measurementType=''; data.measurement=''; data.parameter='';"></v-autocomplete>
        </div>
      </div>
      <div v-if="canShowFactory" class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          ชื่อโรงงาน
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-autocomplete v-model="data.id" :items="forSelectFactory" background-color="white" dense hide-details
                          item-text="name" item-value="id" outlined placeholder="ทั้งหมด"></v-autocomplete>
        </div>
        <div>
          <v-progress-circular v-if="isFetching" indeterminate size="22"></v-progress-circular>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          ประเภทจุดตรวจวัด
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="data.measurementType" :items="forSelectMeasurementType" background-color="white"
                    color="search" dense hide-details item-color="search-item" item-text="name" item-value="id" outlined
                    placeholder="ทั้งหมด" @change="data.measurement=''"></v-select>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          จุดตรวจวัด
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="data.measurement" :items="forSelectMeasurement" background-color="white" dense hide-details
                    item-text="name" item-value="id" outlined placeholder="ทั้งหมด" @change="fetchParameter"></v-select>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          พารามิเตอร์
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="data.parameter" :items="forSelectParameter" background-color="white" dense hide-details
                    item-text="name" item-value="id" outlined placeholder="ทั้งหมด"></v-select>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          ความถี่
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="data.frequency" :items="frequentList" background-color="white" dense hide-details
                    item-text="name" item-value="id" outlined placeholder="--เลือก--"></v-select>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          วันที่เริ่ม
        </div>
        <div class="myInputWidth pr-3 py-2">
          <my-date-picker v-model="data.datefrom"></my-date-picker>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          วันที่สิ้นสุด
        </div>
        <div class="myInputWidth py-2 pr-3 pr-sm-0">
          <my-date-picker v-model="data.dateto" :min="data.datefrom"></my-date-picker>
        </div>
      </div>
      <div class="pl-sm-3 py-2">
        <v-btn color="primary white--text" style="height: 40px;" @click="getExportUrl()">ส่งออก</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MyDatePicker from './myDatePicker'
import utils from '../assets/js/utils'

export default {
  name: 'export-statistic',
  components: { MyDatePicker },
  mounted () {
    // no await for faster fetching order
    if (this.province_id !== 0) {
      this.$store.dispatch('ref/getFactoryByProvinceId', { provinceId: this.province_id })
        .then(data => { this.forSelectFactory = data })
    } else {
      this.$store.dispatch('ref/getFactoryRef')
        .then(data => { this.forSelectFactory = data })
    }
    this.$store.dispatch('ref/getMeasurementType')
    this.$store.dispatch('ref/getFactoryType')
    if (this.factory_id) {
      this.data.id = this.factory_id
    }
  },
  data () {
    return {
      data: {
        selectedFactoryType: 0,
        id: 0,
        measurementType: 0,
        measurement: 0,
        parameter: null,
        frequency: 'daily',
        datefrom: new Date().toISOString().substr(0, 10),
        dateto: new Date().toISOString().substr(0, 10)
      },
      frequentList: [
        {
          id: 'hourly',
          name: 'รายชั่วโมง'
        },
        {
          id: 'daily',
          name: 'รายวัน'
        },
        {
          id: 'monthly',
          name: 'รายเดือน'
        },
        {
          id: 'annual',
          name: 'รายปี'
        }
      ],
      uniqueMeasurementType: [],
      factoryMeasurement: [],
      filteredParameter: [],
      isFetching: false,
      forSelectFactory: []
    }
  },
  methods: {
    getExportUrl () {
      const payload = {
        uuid: this.uuid,
        id: this.data.id || '',
        measurement: this.data.measurement || '',
        parameter: this.data.parameter || '',
        frequency: this.data.frequency || '',
        datefrom: this.data.datefrom || '',
        dateto: this.data.dateto || ''
      }
      const query = new URLSearchParams(payload).toString()
      const url = process.env.VUE_APP_WS_BASE + '/export/measurement/?' + query
      if (utils.getDeviceType() === 3) {
        window.open(url, 'Download')
      } else {
        window.longdoJsInterface.openUrl({ url: url, title: 'ส่งออกข้อมูล' })
          .then(
            (result) => {
              if (!result) console.warn('open url not success with unknown error')
            },
            (error) => {
              if (error.code !== 1) console.warn('open url not success with this code', error)
            }
          )
      }
    },
    async fetchParameter () {
      this.filteredParameter = await this.$store.dispatch('ref/getParameterList', { id_parent: this.data.measurement })
      this.data.parameter = ''
    }
  },
  computed: {
    ...mapState('ref', ['factory', 'measurementType', 'factoryType']),
    ...mapState('user', ['uuid', 'roles', 'factory_id', 'province_id']),
    forSelectFactoryType () {
      return [{ id: 0, name: 'ทั้งหมด' }, ...this.factoryType]
    },
    forSelectMeasurementType () {
      if (this.uniqueMeasurementType.length <= 0) {
        return this.measurementType
      } else {
        const tmp = this.measurementType.filter(item => this.uniqueMeasurementType.includes(item.id))
        return [{ id: 0, name: 'ทั้งหมด' }, ...tmp]
      }
    },
    forSelectMeasurement () {
      if (!this.data.measurementType) {
        return this.factoryMeasurement
      } else {
        const tmp = this.factoryMeasurement.filter(item => String(item.type_id) === String(this.data.measurementType))
        return [{ id: 0, name: 'ทั้งหมด' }, ...tmp]
      }
    },
    forSelectParameter () {
      return [{ id: 0, name: 'ทั้งหมด' }, ...this.filteredParameter]
    },
    canShowFactory () {
      let canShow = true
      canShow = canShow && !this.roles.includes('factory')
      return canShow
    }
  },
  watch: {
    async 'data.id' (to, from) {
      if (!to) {
        return false
      }
      if (to === from) {
        return false
      }
      this.isFetching = true
      this.data.measurementType = ''
      this.factoryMeasurement = await this.$store.dispatch('ref/measurement', { id_parent: to })
      const availableMeasurementType = this.factoryMeasurement.map(item => parseInt(item.type_id))
      const uniqueMeasurementType = []
      availableMeasurementType.forEach(
        item => {
          if (!uniqueMeasurementType.includes(item)) {
            uniqueMeasurementType.push(item)
          }
        }
      )
      this.uniqueMeasurementType = uniqueMeasurementType
      this.isFetching = false
    },
    async 'data.selectedFactoryType' (to, from) {
      if (!to) {
        if (this.province_id !== 0) {
          this.$store.dispatch('ref/getFactoryByProvinceId', { provinceId: this.province_id })
            .then(data => { this.forSelectFactory = data })
        } else {
          this.$store.dispatch('ref/getFactoryRef')
            .then(data => { this.forSelectFactory = data })
        }
      } else {
        this.forSelectFactory = await this.$store.dispatch('ref/getFactoryByFactoryType', { id_parent: to })
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .myLabelWidth {
    width: 105px;
  }

  .myInputWidth {
    max-width: calc(100vw - 12px - 105px - 86px);
  }
}

@media (min-width: 600px) {
  .myInputWidth {
    max-width: 170px;
  }
}
</style>
